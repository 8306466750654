import axios from 'axios'
const tempurl = "https://yoobright.top:8089"
// const tempurl2 = "https://www.keceng.cn/med_recommender"
const tempurl2 = "http://39.108.252.201:5006"
const tempurl3 = "http://192.168.1.46:5006"

const request = axios.create({
    baseURL: tempurl + '/',
    timeout: 20000
})
const request2 = axios.create({
    baseURL: tempurl2 + '/recommend',
})
const request3 = axios.create({
    baseURL: tempurl3 + '/recommend',
})
export {request,request2,request3};